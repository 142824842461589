.inpwidthsmall {
  width: 150px;
}

.listUploadFiles {
  height: 100px;
}

.error-valid {
  border-color: #dc3545 !important;
}

.select-error-valid {
  border: 1px solid#dc3545 !important;
}

.error-msg {
  color: #d22d3d;
}

// .pgbgcolor {
//   background-color: #009688;
//   padding: 0px 0px 30px !important;
//   vertical-align: middle;
// }

.brdhead,
.hdtext {
  color: #FFFFFF !important;
  margin-left: 13px;
}

.topaln {
  margin-top: -30px !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 600 !important;
  line-height: 1.5;
  color: #ffffff !important;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff !important;
  border: 1px solid #DDDEE2;
  border-radius: 0.25rem;
}

.input-group-text i {
  color: #000000 !important;
}

.btn-order {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 500;
}

.btn-order-color {
  background-color: #009688 !important;
  border-color: #000000 !important;
  border-radius: 20px !important;
}

.selectbtn {
  background-color: #FFFFFF !important;
  border-color: #DDDEE2 !important;
  border-radius: 20px !important;
  color: #b7b7b7 !important;
  font-weight: 400 !important;
}

.selectbtn:hover {
  background-color: #B2DFDB !important;
  border-color: #009688 !important;
  border-radius: 20px !important;
  color: #009688 !important;
  font-weight: 400 !important;
}

.btn-primary,
.btn-secondary,
.btn-danger {
  border-radius: 20px !important;
}

.page-main-header .main-header-right .main-header-left {
  padding: 8px 8px !important;
}

.secondaryBtn {
  background-color: #fff !important;
  border-color: #009688 !important;
  color: #009688 !important;
}

/*Loading Start CSS*/

// #cover-spin {
//     position: fixed;
//     width: 100%;
//     inset: 0px;
//     background-color: rgba(255, 255, 255, 0.7);
//     z-index: 9999;
//     display: block;

// }
// .ring
// {
//   position:fixed !important;
//   top:50%;
//   left:50%;
//   transform:translate(-50%,-50%);
//   width:50px;
//   height:50px;
//   //background:#B2DFDB;
//   //border:3px solid #3c3c3c;
//   //border-radius:50%;
//   text-align:center;
//   //line-height:150px;
//   font-family:sans-serif;
//   font-size:20px;
//   color:#0e7970;
//   letter-spacing:4px;
//   text-transform:uppercase;
//   text-shadow:0 0 10px #0e7970;
//   //box-shadow:0 0 20px rgba(0,0,0,.5);
//   z-index: 9999;
// }
// .ring:before
// {
//   content:'';
//   position:absolute;
//   top:-3px;
//   left:-3px;
//   width:100%;
//   height:100%;
//   border:3px solid transparent;
//   border-top:3px solid #0e7970;
//   border-right:3px solid #0e7970;
//   border-radius:50%;
//   animation:animateC 1s linear infinite;
// }
// @keyframes animateC
// {
//   0%
//   {
//     transform:rotate(0deg);
//   }
//   100%
//   {
//     transform:rotate(360deg);
//   }
// }
// @keyframes animate
// {
//   0%
//   {
//     transform:rotate(45deg);
//   }
//   100%
//   {
//     transform:rotate(405deg);
//   }
// }

/*Loading End CSS*/

.imgwidth {
  width: 150px !important;
}

.pad-style {
  padding: 10px !important;
}

.formcmpname {
  background-color: #f8f9fa;
  padding: 10px;
}

.rescheduleImage {
  width: 16px;
  font-size: 15px;
  color: #706F70;
  // margin-left: 10px;
}

.reschedule-div {
  width: 40px;
  background-color: #E9ECEF !important;
  border-radius: 2px;
  margin-left: -4px;
  cursor: pointer;
  padding: 12px;
}

// .reschedule-div img{ padding-top: 14px !important;}

.tableHeader {
  background-color: "#F6F6F6" !important;
  font-weight: "400" !important;
}

.card {
  border-radius: 5px !important;
}

.task_accomplish input[type=date]::-webkit-calendar-picker-indicator {
  background-color: #E9ECEF;
  padding: 15px;
  background: #E9ECEF url("../images/dms/calendar.svg") 30px 30px center no-repeat;
}

.task_accomplish input[type=date] {
  padding: 0 0 0 10px;
}

.swal2-actions button.btn-danger-confirm {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
  border: 0 !important;
  border-radius: .25em !important;
}

.marg-min-top-10 {
  margin-top: -10px;
}

.taskUpdate-table-header {
  background-color: #c4eee9 !important;
  font-weight: 400 !important;
}
.taskUpdate-tasktable-header {
  background-color: #CEEAE8 !important;
  font-weight: 600 !important;
}
.taskUpdate-table-sideHeader {
  // background-color: #F6F6F6 !important;
  font-weight: 500;
}
.taskUpdate-tasktable-sideHeader {
  // background-color: #F6F6F6 !important;
  font-weight: 500;
}
.taskUpdate-tr-border {
  border-bottom: 0.5px solid #f1f0f0;
}

.icon-color {
  color: #706F70 !important;
}

.offcanvas-header {
  display: inline;
}

.offcanvas-width {
  width: 500px;
}

.productionoffcanvas-width {
  width: 800px;
}

.cursor-pointer {
  cursor: pointer;
}

.linenav-padding {
  padding: 8px 16px !important;
}

.labeltexthd {
  color: #009688 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.labeltexthdp {
  font-weight: 400 !important;
}

.clr-complete {
  color: #26d6c7;
  font-weight: bolder;
  font-size: 1.3em;
}

.clr-delay {
  color: #D6444F;
  font-weight: bolder;
  font-size: 1.3em;
}

.clr-nstart {
  color: rgb(232 239 240);

  font-weight: bolder;
  font-size: 1.3em;
}

.clr-inprogress {
  color: rgb(199, 208, 221) !important;
  font-weight: bolder;
  font-size: 1.3em;
}

.clr-delaycomp {
  color: #eb892d;
  font-weight: bolder;
  font-size: 1.3em;
}

.rowborder {
  border: 1px solid rgb(227, 225, 225) !important;
}

.chartfullwidth {

  // min-width:800px !important;
  width: 100% !important;
}


.dangeralt {
  color: #d22d3d;
  font-size: 11px;
}

.dropbtn dropdown-item {
  z-index: 9999 !important;
}

.dropbtnvl button {
  background-color: #F5F6F8 !important;
  border: none !important;
}

.mminutp {
  margin-top: -10px !important;
  z-index: 999 !important;
  padding: 0;
}

.secondaryBtn {
  border-radius: 20px !important;
}

.tableRowCol tr:nth-child(2n+1) {
  // background-color: #F7F7F7;
  background-color: #fff;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none ! important;
  // border: none !important;
}

.swal2-cancel,
.swal2-deny,
.swal2-confirm {
  border-radius: 20px !important;
  //border: none !important;
  border-color: #009688 !important;
}

.swal2-confirm {
  background-color: #009688 !important;
  border-color: #009688 !important;
}

.swal2-cancel,
.swal2-deny {

  // background-color: #E7EAED !important;
  // border-color: #E7EAED !important;
  // color : #3D3D3D !important;
  background-color: #fff !important;
  border: 1px solid #009688 !important;
  color: #009688 !important;
}

.swal2-cancel:hover {
  border: 1px solid #009688 !important;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #009688 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-delete {
  background-color: #C92121 !important;
  border-color: #C92121 !important;
}

rect[x="0"] {
  fill: none !important;
}

svg[aria-label="A chart."] {
  background-color: #ffffff !important;
}

// svg g path{
//   border: 1px solid #d22d3d !important;
// }
// svg g rect{
//   border: 1px solid #d22d3d !important;
// }

// .rowborderbottom{
//   border-bottom: 1px solid #CCC !important;
// }
// .ant-radio-group{ display: none !important;}


.ant-col .header1-text {
  display: none !important;
}

.swal2-confirm {
  padding-left: 25px !important;
  padding-right: 25px !important;
  border: none !important;
}

.swal2-delete {
  background-color: #C92121 !important;
  border-color: #C92121 !important;
}

// [class^=icon-], [class*=" icon-"] {
//   font-family:"Poppins", sans-serif !important;
// }
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
  box-shadow: 0 0 0 0px transparent !important;
}





// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  .ant-row-flex-space-between {

    justify-content: none !important;
    max-width: 500px !important;
  }

  .scheduler-view {

    max-width: 500px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .ant-row-flex-space-between {

    justify-content: none !important;
    max-width: 600px !important;
    //width: 62% !important;
  }

  .scheduler-view {

    max-width: 600px !important;
    // width: 62% !important;
  }

  .RBS-Scheduler-root {
    //width: 80% !important;
    max-width: 600px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ant-row-flex-space-between {

    justify-content: none !important;
    max-width: 800px !important;
    //width: 62% !important;
  }

  .scheduler-view {
    //  width: 62% !important;
    max-width: 800px !important;
  }

  .RBS-Scheduler-root {
    width: 80% !important;
    max-width: 800px !important;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .ant-row-flex-space-between {
    // width: 95% !important;
    justify-content: none !important;
    max-width: 1000px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1000px !important;
  }

  .RBS-Scheduler-root {
    width: 80% !important;
    max-width: 1000px !important;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

  .ant-row-flex-space-between {
    //width: 95% !important;
    justify-content: none !important;
    max-width: 1170px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1170px !important;
  }

  .RBS-Scheduler-root {
    // width: 80% !important;
    max-width: 1150px !important;
  }
}

@media (min-width: 1500px) {

  .ant-row-flex-space-between {
    //width: 95% !important;
    justify-content: none !important;
    max-width: 1200px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1270px !important;
  }

  .RBS-Scheduler-root {
    // width: 80% !important;
    max-width: 1270px !important;
  }
}

@media (min-width: 1600px) {

  .ant-row-flex-space-between {
    //width: 95% !important;
    justify-content: none !important;
    max-width: 1300px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1368px !important;
  }

  .RBS-Scheduler-root {
    // width: 80% !important;
    max-width: 1368px !important;
  }
}

@media (min-width: 1800px) {

  .ant-row-flex-space-between {
    //width: 95% !important;
    justify-content: none !important;
    max-width: 1707px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1688px !important;
  }

  .RBS-Scheduler-root {
    // width: 80% !important;
    max-width: 1688px !important;
  }
}

@media (min-width: 2000px) {

  .ant-row-flex-space-between {
    //width: 95% !important;
    justify-content: none !important;
    max-width: 1850px !important;
  }

  .scheduler-view {
    // width: 95% !important;
    max-width: 1750px !important;
  }

  .RBS-Scheduler-root {
    // width: 80% !important;
    max-width: 1750px !important;
  }
}

.round-tail,
.round-all,
.round-head {
  border-radius: 0px !important;

}

.event-item {
  color: #2b2b2b !important;
}

h6 {
  color: #009688 !important;
}


.subTitleLine {
  width: 150px !important;
}

.subTitleLine2 {
  width: 200px !important;
}

.subTitleLine3 {
  width: 250px !important;
}

.form-control,
.form-select {
  height: 45px !important;
}

.form-label {
  color: #2d2d2d !important;
}

.btn {
  padding: 0.6rem 1.75rem !important;
}

.btn-light {
  border-radius: 20px !important;
}

h5,
.h5 {
  font-size: 1.05rem !important;
}

.selectbtn {
  height: 31px !important;
  padding: 0.2rem 1.75rem !important;

}


/* Define css class for global loading message to cover 
   screen during axios operations */

.loading-indicator:before {
  content: '';
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000 !important;
  color: #000000;
}

.loading-indicator:after {
  //content: 'Loading...';
  content: url('../images/dms/Circles-loader.gif');
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10001 !important;
  color: #000000;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

table.statustable td {
  padding: 0.23em !important;
  vertical-align: middle !important;
  background-color: #FFFFFF !important;
}

.centerAlign {
  text-align: center;
}

.dropdown-basic .dropdown .dropdown-content a {
  color: #2b2b2b !important;
}

.fc-button-primary:disabled,
.fc-button-primary {
  background-color: rgba(255, 220, 40, 0.15) !important;
  color: #000000 !important;
  font-weight: bold !important;
}

.fc-button-group {
  background-color: #009688 !important;
}

.inputdisable {
  background-color: #FFFFFF !important;
}

.inputdisable:hover {
  background-color: #FFFFFF !important;
  border-color: #ccc !important;
}

// .textBgColor{
//   border: 1px solid #26695C !important;
//   padding: 8px;
//   border-radius: 5px;
//   width: 500px !important;
// }
.fc-icon-chevron-right,
.fc-icon-chevron-left {
  color: #ffffff !important;
}

.weekdayoff {
  font-size: 14px !important;
  font-weight: 500;
}

input.chekweekoff:checked {
  color: #ffffff !important;
}

h2.fc-toolbar-title {
  text-transform: capitalize !important;
  ;
  font-weight: 600 !important;
  ;
  line-height: 1.3 !important;
  ;
  color: #2c323f !important;
  ;
  padding: 10px !important;
  font-size: 1.05rem !important;
}

// .fc-today-button{
//   background-color: rgb(199, 208, 221);
// }
.hoildaytit {
  font-weight: 600 !important;
}



@media only screen and (min-width: 992px) and (max-width: 1136px) {
  .qtyCard {
    // float: center !important;
    // color: '#009688';
    font-size: 7px !important;
  }

}

.heaertit {
  font-size: 14px;
  line-height: 1.0 !important;
  letter-spacing: inherit;
  margin-bottom: 0;
  font-weight: 500 !important;
}

.ordsts tr th {
  padding: 0.5rem !important;
}

.icofont {
  font-size: 14px !important;
  font-weight: 500px !important;
}

.actionsty {
  font-size: 14px !important;
  font-weight: 500px !important;
}

.input-group-text2 {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #000000 !important;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff !important;
  border: 1px solid #DDDEE2;
  border-radius: 0.25rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.prodtyphd {
  font-size: 17px !important;
  font-weight: 600 !important;
}

.accvbtn {
  font-size: 0px !important;
}

.skudetailsclr {
  background: #CEEAE8 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  
}
.skuborder {
  border-bottom: 1px solid
}
.b-r-order {
  border-left: 1px solid #E7E9EB!important;
  }

ul.nav-submenu li a {
  background: #D7DADB 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E400;
  color: #000;
}

.datainputmodal {
  max-width: 800px !important;
}

.btnOutline,
.btnOutline:disabled {
  color: #FFFFFF !important;
}

.taslviewmodal {
  background-color: #009688 !important;
  font-weight: 500 !important;
  color: #FFF !important;
  padding: 10px !important;
}

.actalgn {

  margin-left: -25px !important;

}

.tabtopaln {
  margin-top: -30px !important;
}

.tabalgn {
  background-color: #B2DFDB !important;
  height: 30px !important;
}

.showperqty {
  font-size: 11px !important;
  color: #ABABAB !important;
}

.artichead {
  font-size: 18px !important;
  font-weight: 515 !important;
}

.ritemargine {
  margin-right: 540px !important;
}

.ritemarg {
  margin-right: 510px !important;
}

.termsalign {
  text-align: justify !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon~footer {
  margin-left: 0;
}

.inline-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pic_select {
  min-width: 150px;
}

@media only screen and (min-width: 1431px) and (max-width: 1522px) {
  .action_title {
    height: 44px;
  }
}

@media (max-width: 1430px) {
  .action_title {
    height: 58px;
  }
}

.greenSmileText {
  color: #43931d !important;
}

.warningSmileText {
  color: #ed923e !important;
}
.yetToCompeteText {
  color:#EB892D !important;
}
.completedText{
  color:#368C0E !important;
}
.delayText{
  color:#E40D22!important;
}
.yetToStart{
  color:#B1B7BF!important;
}
.fc-dayGridMonth-view .fc-scroller-liquid-absolute {
  overflow: overlay !important;
}

td.text-left {
  text-align: left;
}

.notification-dropdown {
  top: 48px !important;
}

.SKUReportTable tr td {
  vertical-align: middle;
}

.bg-delayCompeletionTextColor {
  background-color: #ed923e !important;
}

.delaydashlineColor {
  color: #FF3838;
  font-weight: bolder;
  font-size: 1.3em;
}

.dashboardColor-complete {
  color: #26A69A;
  font-weight: bolder;
  font-size: 1.3em;
}

.bg-delaychartTextColor {
  background-color: #FF3838 !important;
}

.delaydashTextColor {
  color: #FF3838 !important;
}

.card_blur {
  background-color: rgb(0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  opacity: 2.5 !important;
  color: transparent;
}

@media only screen and (min-width: 292px) and (max-width: 767px) {
  .label_align1 {
    text-align: center !important;
  }

  .label_align2 {
    margin-right: 50px !important;
  }
}

.imgBackground {
  background-color: #26A69A !important;
}

.imageborder {
  border-radius: 0px 20px 20px 0px !important;
  border-left: none !important;
}

.inputborder {
  border-radius: 20px 0px 0px 20px !important;
  border-right: none !important;
}

.modelWidth {
  max-width: 1500px !important;
}

.factSave {
  text-align: center !important;
}

.starColor {
  background-color: #fff !important;
  color: orange !important;
}
.link{
   
  color: #3366CC !important;
}
.comments{
  background-color: #EFEFEF;
}
.chatcomments{
 background-color: #B2D9D6;
}
.input {
  cursor: pointer;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

#htmlStringListCSS>* {
  // Used for html string listing alignment issue
  ol {
    display: inline-block !important;
    padding-left: 20px !important;
  }

  ul {
    padding-left: 20px !important;
    list-style-type: circle !important;
  }
}

.dashboardBorder {
 // border-bottom-color: #C7D0DD !important;
  //border-bottom: 5px solid #C7D0DD  !important;
 // box-shadow: 0px 10px  #C7D0DD  !important;
  //border-top-left-radius: 25px !important;
 // border-bottom: inset !important;
 box-shadow: 0px 5px 0px 0px #D4D4D4 !important;
 /*border-bottom: 0.7rem solid #C7D0DD !important;

*/
//  border-bottom-left-radius: 5px !important;
//  border-bottom-right-radius: 5px !important;
border-radius: 10px !important;
margin-bottom: 5px !important;
}
.borderbottomnone{
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.bgcolormain{
  background-color: #F7F7F7 !important;
}
.overViewSku{
  background-color: #CEEAE8;
 }
.subTitleOrderView{
  // width: 150px !important;
  margin-top: -17px !important;
}
.subTitleOrderSku{
  margin-top: -26px !important;
}
.subTitleOrdercontact{
  margin-top: -13px !important;
}
.chatcommentsleft{
  background-color: #F6F6F6;
}
.higlightChatText{
  background-color: #009688;
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-bottom: 5px !important;
  color: #fff !important;
}

.chatradius {
  border-top-left-radius: 15px !important;
  //  border-top-right-radius: 15px !important;
 }
 .chatradiusleft {
  // border-top-left-radius: 0px !important;
  border-top-right-radius: 15px !important;
 }
 .approved{
  color:#3FC984
 }
 .rejected{
  color:#EF6565
 }
 .submission{
  color:#009688
 }
 .reSubmission{
  color:#529ED1
 }
 .editcompanyprofile{
  margin-top: -12px !important;
}
.editcompanyinfo{
  margin-top: -12px !important;
}
.editcompanytask{
  margin-top: -34px !important;
}
.taskUpdate-table-overview {
  background-color: #CEEAE8 !important;
  font-weight: 400 !important;
}
.taskUpdate-table-side-overview {
  background-color: #fff !important;
  font-weight: 500;
}
.border_company_profile{
  // border-top-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
 }
 .editcompany_background{
  background-color: #009688;
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-bottom: 5px !important;
}
blink {
  animation: 2s linear infinite condemned_blink_effect;
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

// .btnHover{
//   color: '#009688' !important;
//   background-color: '#fff' !important;
// }

.test {
  color: '#009688' !important;
  background-color: '#009688' !important;
  height: '40px' !important;
  width: '200px' !important;
  border-radius: '20px';
  border: '1px solid #009688';
}

.test :hover{
  color: '#fff' !important;
  background-color: '#009688' !important;
}

.input-group-symbol {
  display: flex;
  align-items: flex-start;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300 !important;
  line-height: 1.5;
  color: #000 !important;
  text-align: center;
  white-space: nowrap;
  background-color: #f2f2f2 !important;
  border: 1px solid #DDDEE2;
  border-radius: 0.25rem;
  width: 60px;
}
 .btndashboard{
  border-radius: 5px !important;
  padding:5px !important;
  font-size: 11px;
  font-weight: 300;
  padding: 0.375rem 1.75rem;
  
 }
 .completedashboard{
  background: #27A79B !important;
  color:#fff
 }
 .delaydashboard{
  background: #FF3838 !important;
  color:#fff
 }
 .delaycompletdashboard{
  background: #E69020 !important;
  color:#fff
 }
 .inprogressdashboard{
  background: #C7D0DD !important;
  color:#000
 } 
 .yettostartdashboard{
  background: #E8EFF0 !important;
  color:#000

 }
 .btndashboardlable{
  border-radius: 5px !important;
  padding:2px !important;
 }
 .cuttingdashboard{
  background: #4A70B1 !important;
  color:#fff;
 } 
 .sewingdashboard{
  background: #639EBD !important;
  color:#fff;
 } 
 .packingdashboard{
  background: #51B9CC !important;
  color:#fff;
 }
 .dashboarddropdown{
  border:1px solid#DDDEE2 !important;
  padding:10px !important;
  border-radius: 5px!important;
  font-size: 14px !important;
  // width:80% !important;

 }
 .dashboardinnerdrop  {
  line-height: 0.7em !important;
 padding: 5px !important;
 }
 .tablehight td{
  padding-bottom: 0.35rem !important;
 }
 .collopseradise{
  border-radius: 0.75rem !important;
  border: none !important;
  box-shadow: 0px 2px 23px -10px #d5d5d5 !important;
 }
 .dashboardIcon{
  width: 30px !important;
  height: 30px !important;
  border-radius: 7px !important;
}

.dashboardIconActive{
  width: 30px !important;
  height: 30px !important;
}

.staffIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #50cbd8 !important;
  border-radius: 7px !important;
}

.orderStatusIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #46A0E5 !important;
  border-radius: 7px !important;
}

.orderStatusIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #46A0E5 !important;
  border-radius: 7px !important;
}

.taskUpdateIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #E2AD00 !important;
  border-radius: 7px !important;
}

.taskUpdateIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #E2AD00 !important;
  border-radius: 7px !important;
}

.pendingTaskIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #D4313C !important;
  border-radius: 7px !important;
}

.pendingTaskIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #D4313C !important;
  border-radius: 7px !important;
}

.dataInputIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #F58A02 !important;
  border-radius: 7px !important;
}

.dataInputIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #F58A02 !important;
  border-radius: 7px !important;
}

.settingIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #50cbd8 !important;
  border-radius: 7px !important;
}

.settingIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #50cbd8 !important;
  border-radius: 7px !important;
}
.samIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #458C41 !important;
  border-radius: 7px !important;
}
.samIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #458C41 !important;
  border-radius: 7px !important;
}


.reportsIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #009688 !important;
  border-radius: 7px !important;
}

.reportsIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #009688 !important;
  border-radius: 7px !important;
}

.rolesIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #CE5D7D !important;
  border-radius: 7px !important;
}

.rolesIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #CE5D7D !important;
  border-radius: 7px !important;
}


.staffIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #7C71F8 !important;
  border-radius: 7px !important;
}

.staffIconActive{
  width: 30px !important;
  height: 30px !important;
  background-color: #7C71F8 !important;
  border-radius: 7px !important;
}
.inquirytoDms{
  width: 30px !important;
  height: 30px !important;
  background-color:  #4C8DDA !important;
  border-radius: 7px !important;
}

.helpIcon{
  width: 30px !important;
  height: 30px !important;
  background-color: #4A72B0 !important;
  border-radius: 7px !important;
}
/******************************************************/
.profile-pic 
{
  position: relative;
  display: inline-block;
} 
.profile-pic:hover {
  z-index: 1000;
  opacity: 0.7;
  // background-color: grey !important;
}

.profile-pic:hover .edit {
  display: block;
}

.profile-pic:hover .test {
  z-index: -1000;
}

.edit {
  position: absolute;
  right: 14%;
  top: 14%;
  display: none;
}

.upload-logo{
  position: relative;
  display: inline-block;
width:120px;
height: 120px;
}
.logoimg {
  position: absolute;
  right: 24%;
  top: 30%;
 // z-index: -1000;
}
.circle{
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  border: 2px solid rgb(255,255,255) !important;
  //background-color: #f1f1f1;
  //align-self: flex-end !important;
  position: relative;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.orderStatusBar td{
padding: 0.5rem !important;
}
.bg-lowbar{
  height: 10px;
  background-color: #FF3169 !important;
}
.bg-fullbar{
  height: 10px;
  background-color: #00B38E !important; 
}

.containerClass img {
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
}

.selectedLogo{
  border: 1px solid #009688 !important;
  border-radius: 10px !important;
  cursor: pointer;
  // box-shadow: 0 0 05px #009688 !important;
}

// transition: all 0.2s ease-out;
// box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
// top: -4px;
// border: 1px solid #cccccc;
// background-color: white;

@media (max-width: 600px) {
  .labelSize{
    font-size: 8px  !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 700px) {
  .labelSize{
    font-size: 10px  !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .topDelayedTasks{
    height: 245px !important;
  }
  .labelSize{
    font-size: 10px  !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .topDelayedTasks{
    height: 275px !important;
  }
  .labelSize{
    font-size: 12px  !important;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .topDelayedTasks{
    height: 290px !important;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1600px) {
  .topDelayedTasks{
    height: 320px !important;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1650px) {
  .topDelayedTasks{
    height: 340px !important;
  }
}
.topDelayedTasks{
  height: 410px;
}

.productionStatusCard{
  border-radius: 0.75rem !important;
  margin-bottom: 2%;
}

// @media only screen and (min-width: 1200px) and (max-width: 1430px) {
//   .container {
//     display: grid;
//     grid-template-columns: 20fr 5fr 5fr;
//     grid-gap: 10px;
//   }
// }

.TaskStatusCard{
  border-radius: 1rem !important;
}
.proTD{
  border-radius: 0.75rem 0px 0px 0.75rem !important;
}
.tableproductionStatusCard {
  border-radius: 1.25rem !important;
}
.taskCardBorder{
  border-radius:  0px 0px  1.25rem 1.25rem!important;
}
.orderStatusBar td{
  padding: 0.03rem !important;
  }

.sliderAlign td {
  padding-top: 0.03rem !important;
  padding-bottom: 0%;
}
.noDataPolarChart {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rc-slider {
  pointer-events: none;
}
// .scheduler-bg-table td{
//   background-color: white !important;
// }
// .scheduler-bg-table th{
//   background-color:#f7f7f7 !important;
//   color: #000000 !important; 
// }

#sidebar-menu{
  margin: 0px !important;
}

@media only screen and (max-width: 1380px)  {
  #sidebar-menu{
    margin-top: 20px !important;
  }
}

.reportHeader:hover {

  box-shadow: 0 0 20px rgba(0,0,0,.12);

  // transition: all 0.2s ease-out;
  // box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  // top: -2px;
  // border: 1px solid #cccccc;
  // background-color: white;


  // transform: translateY(-2px) !important;
  // box-shadow:2px 2px 5px 0px rgba(0,150,136,1) !important;
}

@media (min-width: 1200px) and (max-width: 1500px)
{
.ant-row-flex-space-between {
 
    //  max-width: 1300px !important; 
     max-width: 63% !important;
}
}
@media (min-width: 1501px) and (max-width: 1600px)
{
.ant-row-flex-space-between {
   
    //  max-width: 1300px !important; 
     max-width: 70% !important;
}
}

@media (min-width: 1601px) and (max-width: 1700px)
{
.ant-row-flex-space-between {
   
    //  max-width: 1300px !important; 
     max-width: 73% !important;
}
}

@media (min-width: 1701px) and (max-width: 1800px)
{
.ant-row-flex-space-between {
  
    //  max-width: 1300px !important; 
     max-width: 74% !important;
}
}
@media (min-width: 1801px)
{
.ant-row-flex-space-between {
  
    //  max-width: 1300px !important; 
     max-width: 76% !important;
}
}

.rmdp-panel{
  min-width: 135px !important;
}

.rmdp-arrow{
  border: solid #009688 !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-panel-header{
  min-width: 180px !important;
  font-weight: 500 !important;
}
.rmdp-day rmdp-selected{
  background-color: #009688 !important;
}

.rmdp-day span:hover{
  background-color: #009688 !important;
  color: #fff !important;
}
.rmdp-day.rmdp-selected span:not(.highlight){
  background-color: #009688 !important;
  color: #fff !important;
}
.rmdp-week-day{
  color: #009688 !important;
}
.rmdp-prime.rmdp-wrapper {
  border: 1px solid #8798ad;
}

.rmdp-prime .rmdp-calendar {
  padding: 0 !important;
}
.rmdp-panel-body{
  background-color: #009688 !important;
} 
.rmdp-panel-body span{
  background-color: #009688 !important;
}
.rmdp-panel-body li{
  background-color: #009688 !important;
}

.rmdp-arrow-container:hover {
  background-color: #fff !important;
}

.rmdp-day.rmdp-today span{
  background-color: #02cfbc !important;
  color: #fff !important;
}

.rmdp-prime .rmdp-header,
.rmdp-prime .rmdp-panel-header {
  border-bottom: 1px solid #8798ad;
  margin-top: 0 !important;
  overflow-y: hidden !important;
}

.rmdp-prime .rmdp-time-picker {
  border-top: 1px solid #8798ad;
  margin-bottom: 0 !important;
}

.rmdp-prime .rmdp-panel-body li {
  border-radius: 5px;
}

.rmdp-prime .rmdp-border-top {
  border-top: 1px solid #8798ad !important;
}

.rmdp-prime .rmdp-border-bottom {
  border-bottom: 1px solid #8798ad !important;
}

.rmdp-prime .rmdp-border-left {
  border-left: 1px solid #8798ad !important;
}

.rmdp-prime .rmdp-border-right {
  border-right: 1px solid #8798ad !important;
}

.rmdp-prime.ep-arrow::after {
  box-shadow: 0 0 6px #404050 !important;
}
.knobchart-align input {
  height: 120% !important; 
}

.rmdp-toolbar{
  border: none !important;
}
.rmdp-toolbar div{
  background-color: #009688 !important;
  border-radius: 15px !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-red:hover{
  background-color: #ff6687 !important;
}
.rmdp-day:not(.rmdp-deactive) .highlight-red:hover{
  background-color: #ff6687 !important;
}

.containerDiv {
  display: flex;
  align-items: center;
  justify-content: center
}

.text {
  font-size: 20px;
  padding-left: 20px;
}

.partialDeliveryModal {
  max-width: 900px !important;
}

.verticalAlignment{
  align-items: center !important;
}
.verticalAlignmentStart{
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}
.verticalAlignmentEnd{
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a2a2; 
}

.form-control[readonly]{
  background-color: #f2f2f2 !important;
}

.form-control:disabled{
  background-color: #f2f2f2 !important;
}

.form-select:disabled {
  background-color: #f2f2f2 !important;
}

.enabledDeliveryDate{
  font-weight: 500 !important;
}

.css-1s2u09g-control{
  height: 65px !important;
}

.css-1pahdxg-control{
  border-color: #DDDEE2 !important;
  box-shadow: 0 0 0 1px #DDDEE2 !important;
  height: 65px !important;
}

.css-1pahdxg-control:hover{
  height: 65px !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}
.css-1n7v3ny-option{
  background-color: #fff !important;
}
.css-9gakcf-option{
  background-color: #dbf0ef !important;
  color: #009688 !important;
}

.deliveryBtn{
  padding: 0.6rem 0.75rem !important;
  height: 35px;
  width: 110px;
  border-radius: 18px;
  border-color: rgb(204, 204, 204);
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: rgb(135, 135, 135);
  font-weight: 600;
  cursor: unset;
}

.basicInfoTableBody{
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 1483px){
  .cardBodyHeight{
    height: 83px !important;
  }
}
.dasboardTablePadding td{
  padding: 0.5rem !important;
  padding-left: 3.5rem !important;
}

.dasboardTablePadding th{
  padding-left: 3.5rem !important;
}

.removeBottomBorder{
  border-bottom: 0px solid #f2f2f2;
}

.taskStatusTablePadding td{
  padding:1rem !important;
  padding-left: 3.5rem !important;
}
.usersettingsPadding td{
  padding:1rem !important;
  padding-left: 1.2rem !important;
}
.taskStatusTablePadding th{
  padding-left: 3.5rem !important;
}
.page-body{
  padding: 0px 0px 0px 0px !important;
}
.dashboardBackground{
  background-color: #f4f4f4  !important;
}
.rc-slider-track{
  height: 35% !important;
}
.rc-slider-handle{
  border-width: 4px !important;
}
.cardRadius{
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 0px 0px 5px #e3e3e3 !important;
}
.textHeight{
  height: 100px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .fontSizeHeader .u-step-title {
    font-size: 10px !important;
  }
  .u-step-desc .fontSizeContent{
    font-size: 10px !important;
  }
}

.custom-table-striped tbody tr:nth-child(even) {
  background-color: #F8F8F8; /* grey color */
}

.custom-table-striped tbody tr:nth-child(odd) {
  background-color: #ffffff; /* white color */
}

.viewMaterialsLabel Label{
font-weight: 600 !important;
}
.task_title{
  margin-top: -20px !important;
}

/****** View BackgroundColor Input Field ******/
.css-1insrsq-control{
  background-color: #A8A8A8 !important;
}

// .profile-pic img{
//   border: 1px solid #bfbfbf !important;
// }

.icon-container {
  border-radius: 100% !important;
  border: 1px solid #fff !important;
}
.fullcalnone{
  background-color: transparent !important;
}
.taskupdatemodal {
  max-width: 1200px !important;
}

@media only screen and (min-width: 200px) and (max-width: 992px) {
  .orderHeaderRow{
    justify-content: center !important;
    text-align: center !important;
    column-gap: 10px;
  }
  .orderHeaderCol1{
    padding: 0px; 
  }
  .labelSampleIcon{
    margin-top: 10px;
    width: 25px;
    height: 25px;
  }
  .heading{
    font-size: 15px;
  }
  .subHeading{
    font-size: 12px !important;
    color: #A3AFB7;
  }
  .subHeadingActive{
    font-size: 10px !important;
    color: #000;
  }
  .selectedHeaderIcon{
    height: 40px !important;
  }
  .unselectedHeaderIcon{
    height: 40px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1282px) {
  .orderHeaderRow{
    justify-content: center !important;
    text-align: center !important;
    column-gap: 150px;
  }
  .orderHeaderCol1{
    padding: 0px; 
  }
  .labelSampleIcon{
    margin-top: 10px;
    width: 25px;
    height: 25px;
  }
  .heading{
    font-size: 11px;
  }
  .subHeading{
    font-size: 10px !important;
    color: #A3AFB7;
  }
  .subHeadingActive{
    font-size: 10px !important;
    color: #000;
  }
  .selectedHeaderIcon{
    height: 40px !important;
  }
  .unselectedHeaderIcon{
    height: 40px !important;
  }
}

@media only screen and (min-width: 1282px) and (max-width: 1600px) {
  .orderHeaderRow{
    justify-content: center !important;
    text-align: center !important;
    column-gap: 150px !important;
  }
  .orderHeaderCol1{
    padding: 0px; 
  }
  .labelSampleIcon{
    margin-top: 10px;
    width: 25px;
    height: 25px;
  }
  .heading{
    font-size: 11px;
  }
  .subHeading{
    font-size: 10px !important;
    color: #A3AFB7;
  }
  .subHeadingActive{
    font-size: 10px !important;
    color: #000;
  }
  .selectedHeaderIcon{
    height: 40px !important;
  }
  .unselectedHeaderIcon{
    height: 40px !important;
  }
}

.selectedHeaderIcon{
  height: 49px;
}

.unselectedHeaderIcon{
  height: 49px;
}

.orderHeaderColActive{
  cursor: pointer;
}

.orderHeaderCol{
  cursor: pointer;
  background-color: #F5F5F6;
}

.orderHeaderColActive :hover{
  background-color: #fff;
  color: #000;
  border-radius: 5px 5px 5px 5px;
  .headingActive{
    color: #000 !important;
  }
  .subHeadingActive{
    color: #000 !important;
  }
}

.orderHeaderCol :hover{
  cursor: pointer;
  background-color: #fff;
  color: #000 !important;
  border-radius: 5px 5px 5px 5px;
    .subHeading{
      color: #000 !important;
    }
}

.orderHeaderCol2 :hover{
  cursor: pointer;
  background-color: #00C9B4;
  color: #fff !important;
    .subHeading{
    color: #fff !important;
    }
}
.orderHeaderCol3 :hover{
  cursor: pointer;
  background-color: #475094;
  color: #fff !important;
    .subHeading{
    color: #fff !important;
    }
}
.orderHeaderCol4 :hover{
  cursor: pointer;
  background-color: #EA95B4;
  color: #fff !important;
    .subHeading{
    color: #fff !important;
    }
}
.orderHeaderCol5 :hover{
  cursor: pointer;
  background-color: #F2AC46;
  color: #fff !important;
    .subHeading{
    color: #fff !important;
    }
}
.orderHeaderCol6 :hover{
  cursor: pointer;
  background-color: #FE6C27;
  color: #fff !important;
    .subHeading{
    color: #fff !important;
    }
}

.reducedTablePadding{
  padding: 0.25rem !important;
}

.swal2-textarea:focus{
  border-color: #e6edef !important;
  box-shadow: 0 0 0 0.2rem rgba(171,140,228,.05) !important;
}

.removeTableBottomBorder > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.submenuActive{
  background-color: #F2F6F6 !important;
  color: #005A52 !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
  font-family: "Poppins", "Noto Sans JP", sans-serif !important;
  font-size: 14px !important;
  padding-left: 10px !important;
}

.submenuInActive{
  background-color: #F2F6F6 !important;
  color: #000 !important;
  letter-spacing: 0px !important;
  font-family: "Poppins", "Noto Sans JP", sans-serif !important;
  font-size: 14px !important;
}
@media only screen and (min-width: 991px) and (max-width: 1089px) { 
  .pgbgcolor {
    background-color: #009688;
    padding: 35px 0px 30px !important;
    vertical-align: middle;
  }
}
@media (min-width: 1090px) { 
  .pgbgcolor {
    background-color: #009688;
    padding: 35px 0px 30px !important;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1920px) { 
  .pgbgcolor {
    background-color: #009688;
    padding: 20px 0px 30px  !important;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) { 
  .pgbgcolor {
    background-color: #009688;
    padding: 35px 0px 20px !important;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 480px) and (max-width: 576px) { 
  .pgbgcolor {
    background-color: #009688;
    padding: 25px 0px 30px  !important;
    vertical-align: middle;
  }
}
@media (max-width: 480px) { 
  .pgbgcolor {
    background-color: #009688;
    margin-top: -23px;
    padding: 20px 0px 30px !important;
    vertical-align: middle;
  }
}
#approveCommentsId{
  height: 6.65em !important;
}

@media only screen and (min-width: 1350px) and (max-width: 1700px) { 
  .tableViewRes{
    table-layout: fixed!important;
    width:1700px
  }
  .tableResScroll {
    overflow-x: auto !important;
    max-width: 100%; 
    max-height: 400px; 
  }
}

@media (max-width: 1350px) {
  .tableViewRes{
    table-layout: fixed!important;
    width:2000px
  }
  .tableResScroll {
    overflow-x: auto !important;
    max-width: 100%; 
    max-height: 400px; 
  }
}
.fixTableHead {
  overflow-y: auto;
  height: 80vh;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff; 
  z-index: 2;
}
@media (max-width: 1350px) {
  .fixTableHead {
    overflow-y: auto;
    height: 60vh;
  }
  .fixTableHead thead th {
    position: sticky;
    top: 0;
    background-color: #fff; 
    z-index: 2;
  } 
}
@media only screen and (min-width: 1350px) and (max-width: 1700px) { 
  .fixTableHead {
    overflow-y: auto;
    height: 70vh;
  }
  .fixTableHead thead th {
    position: sticky;
    top: 0;
    background-color: #fff; 
    z-index: 2;
  } 
}
.staffpad td{
  padding: 6px !important;
}
.holidayalign{
  padding: 15px !important;
}
.holidaylistpad{
  padding-bottom: 25px !important;
}
.holidaynodatapad{
  padding-bottom: 30px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) { 
  .holidaynodatapad{
    padding-bottom: 30px !important;
  }
  .holidaylistpad{
    padding-bottom: 43px !important;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1076px ) { 
  .holidaynodatapad{
    padding-bottom: 35px !important;
  }
  .holidaylistpad{
    padding-bottom: 40px !important;
  }
}
.blurmail{
  filter: blur(3.5px);      
}
span.langtxtcase{
  margin-left: 5px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.1px !important;
}
