/**=====================
  49. Authentication CSS Start
==========================**/
// login page //
.show-hide {
  position: absolute;
  top: 18px;
  right: 30px;
  transform: translateY(-50%);
  text-transform: capitalize;
  z-index: 2;
  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-default);
    .show-hide span:before {
      content: "hide";
    }
    &.show {
      &:before {
        content: "show";
      }
    }
  }
}
.needs-validation {
  label {
    opacity: 0.7;
    margin-bottom: 10px;
  }
  .invalid-tooltip {
    top: -35px;
    right: 10px;
  }
}
.login-card {
  background-color: #fff!important;
  // height: 100vh;
  // display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 100vh;
  margin: 0 auto;
  padding: 30px 12px;
}
.login-form {
  padding: 30px;
  // width: 450px;
  background-color: #fff !important;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 22px;
  }
  h6 {
    font-size: 14px;
    margin-bottom: 25px;
    color: $light-text;
  }
  .btn {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    display: block;
  }
  p {
    font-weight: 600;
    text-align: center;
    margin-bottom: unset;
  }
  .form-group {
    position: relative;
    label {
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 5px;
    }
    .input-group-text {
      background-color: rgba($primary-color, 0.1);
      border: none;
      color: var(--theme-default);
    }
    input {
      transition: all 0.3s ease;
    }
    .form-control {
      &::placeholder {
        font-weight: 400;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .checkbox {
      display: inline-block;
      label {
        padding-top: 0;
        margin-top: 0;
        color: $light-text;
        span {
          color: rgba($primary-color, 0.5);
        }
      }
    }
    .link {
      float: right;
      font-weight: 500;
    }
    .small-group {
      display: flex;
      align-items: center;
      .input-group {
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
      }
    }
  }
  ul.login-social {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      display: inline-block;
      width: 35px;
      height: 35px;
      background-color: rgba($primary-color, 0.08);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      a {
        line-height: 1;
        svg {
          width: 16px;
          height: auto;
        }
      }
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }
  .login-social-title {
    position: relative;
    margin-bottom: 20px;
    z-index: 2;
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $light-text;
      z-index: -1;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      color: $light-text;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $white;
    }
  }
}
@media (max-width: 575px) {
  .login-form {
    .form-group {
      .link {
        float: unset;
      }
    }
    .btn {
      margin-left: unset;
    }
  }
}
@media (max-width: 480px) {
  .login-form {
    width: 100%;
  }
}
/**==========================
  49. Authentication CSS Ends
==========================**/


 @media (max-width: 1200px){
  .order-1{
    display: none;
  }
 }